import '../../app/vendors/css/pickers/flatpickr/flatpickr.min.css';
import flatpickr from "flatpickr";
import feather from "feather-icons";
import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import _ from "lodash";

function onSaveButtonNOE_9ee1c730(event) {
    let form = $("#new-one-time-expense-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewOneTimeExpenseForm_9ee1c730(event) {
    toggleLoading('#saveButtonNOE', '#loadingButtonNOE');
    event.preventDefault();
    let form = $(this);
    let oneTimeExpensesHelper = $('#one-time-expenses-helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = oneTimeExpensesHelper.data('add');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            $('#oneTimeExpensesTable').data('table').ajax.reload(null, false);

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNOE', '#loadingButtonNOE');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCOE_9ee1c730(event) {
    let form = $("#change-one-time-expense-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangeOneTimeExpenseForm_9ee1c730(event) {
    toggleLoading('#saveButtonCOE', '#loadingButtonCOE');
    event.preventDefault();
    let form = $(this);
    let oneTimeExpensesHelper = $('#one-time-expenses-helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = oneTimeExpensesHelper.data('edit');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            form.removeClass("was-validated");
            $('#oneTimeExpensesTable').data('table').ajax.reload(null, false);

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCOE', '#loadingButtonCOE');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onNewExpenseGrossCost_9ee1c730(event) {
    let current = parseFloat($(event.target).val());
    let vatRate = parseFloat($('#new-one-time-expense-form #one_time_expense_vatRateId option:selected').data('rate')) / 100 + 1;

    $('#new-one-time-expense-form #one_time_expense_netCost').val((current / vatRate)
        .toFixed($('#new-one-time-expense-form #one_time_expense_currency option:selected').data('digits')));
}

function onNewExpenseNetCost_9ee1c730(event) {
    let current = parseFloat($(event.target).val());
    let vatRate = parseFloat($('#new-one-time-expense-form #one_time_expense_vatRateId option:selected').data('rate')) / 100 + 1;

    $('#new-one-time-expense-form #one_time_expense_grossCost').val((current * vatRate)
        .toFixed($('#new-one-time-expense-form #one_time_expense_currency option:selected').data('digits')));
}

function onNewExpenseVat_9ee1c730() {
    let rate = parseFloat($('#new-one-time-expense-form #one_time_expense_vatRateId option:selected').data('rate'));

    $('#new-one-time-expense-form #one_time_expense_netCost')
        .val((parseFloat($('#new-one-time-expense-form #one_time_expense_grossCost').val()) / (rate / 100 + 1))
            .toFixed($('#new-one-time-expense-form #one_time_expense_currency option:selected').data('digits')));
}

function onChangeExpenseGrossCost_9ee1c730(event) {
    let current = parseFloat($(event.target).val());
    let vatRate = parseFloat($('#change-one-time-expense-form #one_time_expense_vatRateId option:selected').data('rate')) / 100 + 1;

    $('#change-one-time-expense-form #one_time_expense_netCost').val((current / vatRate)
        .toFixed($('#change-one-time-expense-form #one_time_expense_currency option:selected').data('digits')));
}

function onChangeExpenseNetCost_9ee1c730(event) {
    let current = parseFloat($(event.target).val());
    let vatRate = parseFloat($('#change-one-time-expense-form #one_time_expense_vatRateId option:selected').data('rate')) / 100 + 1;

    $('#change-one-time-expense-form #one_time_expense_grossCost').val((current * vatRate)
        .toFixed($('#change-one-time-expense-form #one_time_expense_currency option:selected').data('digits')));
}

function onChangeExpenseVat_9ee1c730() {
    let rate = parseFloat($('#change-one-time-expense-form #one_time_expense_vatRateId option:selected').data('rate'));

    $('#change-one-time-expense-form #one_time_expense_netCost')
        .val((parseFloat($('#change-one-time-expense-form #one_time_expense_grossCost').val()) / (rate / 100 + 1))
            .toFixed($('#change-one-time-expense-form #one_time_expense_currency option:selected').data('digits')));
}

export function initOneTimeExpensesPage() {
    require('../datatables');
    window.flatpickr = flatpickr;

    if ($('html').attr('lang') === 'hu') {
        const hu = require("flatpickr/dist/l10n/hu").default.hu;
        flatpickr.localize(hu);
    }

    let table = $('#oneTimeExpensesTable');
    let oneTimeExpensesHelper = $('#one-time-expenses-helper');

    window.changeOneTimeExpense = function (id) {
        $.ajax({
            url: oneTimeExpensesHelper.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-one-time-expense-div").html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });

                $('#change-one-time-expense-form #one_time_expense_date').flatpickr({
                    dateFormat: "Y.m.d.",
                    allowInput: true
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deleteOneTimeExpense = function (id, name) {
        deleteDialog(id, decodeURI(name), oneTimeExpensesHelper.data('delete-confirm'), oneTimeExpensesHelper.data('delete-text'), oneTimeExpensesHelper.data('delete'), () => {
            $('#oneTimeExpensesTable').data('table').ajax.reload(null, false);
        });
    };

    $('#new-one-time-expense-form #one_time_expense_date').flatpickr({
        dateFormat: "Y.m.d.",
        allowInput: true
    });

    table.data('table', table.DataTable({
        "responsive": true,
        "language": {
            "url": oneTimeExpensesHelper.data('lang')
        },
        "columnDefs": [
            {
                'targets': 'no-sort',
                'orderable': false
            },
            {
                targets: 1,
                responsivePriority: 2,
            },
            {
                targets: 0,
                responsivePriority: 1
            }
        ],
        "buttons": [
            {
                extend: 'collection',
                className: 'btn btn-outline-secondary me-2',
                text: feather.icons.share.toSvg({class: 'font-small-4 me-50'}) + oneTimeExpensesHelper.data('export'),
                buttons: [
                    {
                        extend: 'print',
                        text: feather.icons.printer.toSvg({class: 'font-small-4 me-50'}) + oneTimeExpensesHelper.data('print'),
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        },
                        orientation: 'landscape',
                        customize: function (win) {
                            let css = '@page { size: landscape; }',
                                head = win.document.head || win.document.getElementsByTagName('head')[0],
                                style = win.document.createElement('style');
                            style.type = 'text/css';
                            style.media = 'print';
                            if (style.styleSheet) {
                                style.styleSheet.cssText = css;
                            } else {
                                style.appendChild(win.document.createTextNode(css));
                            }
                            head.appendChild(style);
                        }
                    },
                    {
                        extend: 'csvHtml5',
                        text: feather.icons['file-text'].toSvg({class: 'font-small-4 me-50'}) + 'Csv',
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    },
                    {
                        extend: 'excelHtml5',
                        text: feather.icons.file.toSvg({class: 'font-small-4 me-50'}) + 'Excel',
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    },
                    {
                        extend: 'pdfHtml5',
                        text: feather.icons.clipboard.toSvg({class: 'font-small-4 me-50'}) + 'Pdf',
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    },
                    {
                        extend: 'copyHtml5',
                        text: feather.icons.copy.toSvg({class: 'font-small-4 me-50'}) + oneTimeExpensesHelper.data('copy'),
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    }
                ],
                init: function (api, node) {
                    $(node).removeClass('btn-secondary');
                    $(node).parent().removeClass('btn-group');
                    setTimeout(function () {
                        $(node).closest('.dt-buttons').removeClass('btn-group').addClass('d-inline-flex');
                    }, 50);
                }
            }
        ],
        "dom": '<"card-header border-bottom p-1"<"head-label"><"dt-action-buttons text-end"B>><"d-flex justify-content-between align-items-center mx-0 row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>t<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
        "order": [[0, "desc"]],
        "lengthMenu": [[10, 25, 50, 100, 500, 1000, -1], [10, 25, 50, 100, 500, 1000, oneTimeExpensesHelper.data('all')]],
        "pageLength": 25,
        "orderCellsTop": false,
        "paging": true,
        "orderClasses": false,
        "deferRender": true,
        "processing": true,
        "serverSide": true,
        "searchDelay": 750,
        "stateSave": true,
        "ajax": {
            "url": oneTimeExpensesHelper.data('url'),
            "async": true,
            "cache": false,
            "method": 'GET',
            "pages": 2
        }
    }).on('draw.dt', function () {
        $('.dataTables_filter input').removeClass('form-control-sm').addClass('form-control');
        $('.dataTables_length select').removeClass('form-select-sm').removeClass('form-control-sm').addClass('form-select');
        $("td").each(function () {
            $(this).addClass("text-center");
        });
        feather.replace({
            width: 14,
            height: 14
        });
    }).on('responsive-display', function () {
        feather.replace({
            width: 14,
            height: 14
        });
    }));

    let search = $('input[type=search].form-control');

    search.on("keyup", _.debounce(function () {
        table.search(search.val()).draw();
    }, 500));

    $("#saveButtonNOE").on("click", onSaveButtonNOE_9ee1c730);
    $("#new-one-time-expense-form").on("submit", onNewOneTimeExpenseForm_9ee1c730);
    $("#saveButtonCOE").on("click", onSaveButtonCOE_9ee1c730);
    $("#change-one-time-expense-form").on("submit", onChangeOneTimeExpenseForm_9ee1c730);

    $("#new-one-time-expense-form #one_time_expense_grossCost").on("input", onNewExpenseGrossCost_9ee1c730);
    $("#new-one-time-expense-form #one_time_expense_netCost").on("input", onNewExpenseNetCost_9ee1c730);
    $("#new-one-time-expense-form #one_time_expense_vatRateId").on("change", onNewExpenseVat_9ee1c730);

    $(document).on("input", "#change-one-time-expense-form #one_time_expense_grossCost", onChangeExpenseGrossCost_9ee1c730);
    $(document).on("input", "#change-one-time-expense-form #one_time_expense_netCost", onChangeExpenseNetCost_9ee1c730);
    $(document).on("input", "#change-one-time-expense-form #one_time_expense_vatRateId", onChangeExpenseVat_9ee1c730);
}

export function unloadOneTimeExpensesPage() {
    delete window.flatpickr;
    delete window.changeOneTimeExpense;
    delete window.deleteOneTimeExpense;

    $('.flatpickr-calendar').remove();
    $('#oneTimeExpensesTable').DataTable().destroy();
    $("#saveButtonNOE").off("click", onSaveButtonNOE_9ee1c730);
    $("#new-one-time-expense-form").off("submit", onNewOneTimeExpenseForm_9ee1c730);
    $("#saveButtonCOE").off("click", onSaveButtonCOE_9ee1c730);
    $("#change-one-time-expense-form").off("submit", onChangeOneTimeExpenseForm_9ee1c730);

    $("#new-one-time-expense-form #one_time_expense_grossCost").off("input", onNewExpenseGrossCost_9ee1c730);
    $("#new-one-time-expense-form #one_time_expense_netCost").off("input", onNewExpenseNetCost_9ee1c730);
    $("#new-one-time-expense-form #one_time_expense_vatRateId").off("change", onNewExpenseVat_9ee1c730);

    $(document).off("input", "#change-one-time-expense-form #one_time_expense_grossCost", onChangeExpenseGrossCost_9ee1c730);
    $(document).off("input", "#change-one-time-expense-form #one_time_expense_netCost", onChangeExpenseNetCost_9ee1c730);
    $(document).off("input", "#change-one-time-expense-form #one_time_expense_vatRateId", onChangeExpenseVat_9ee1c730);
}
